<template>
    <div class="uploads">
        <pushPc ref="false" v-if="!isMobile" @setItem="setItem" @socket="socket" @sendSocket="sendSocket"
            @getInstall="getInstall" />
        <pushPhone ref="true" v-else @socket="socket" @setItem="setItem" @sendSocket="sendSocket"
            @getInstall="getInstall" />
    </div>
</template>
<script>
import pushPc from './pushPc.vue'
import pushPhone from './pushPhone.vue'
import { post } from "@/utils/request";
import { Toast } from "vant";
export default {
    components: { pushPc, pushPhone },
    data() {
        return {
            isMobile: true,
            ws: '',
            num: -1,// 桌面连接websocket连接了多少次
            wsList: [],
            urlName: '',
            params: {},
            flag: false,// 获取设备列表接口是否请求完成
        }
    },
    created() {
        this.isMobile = this.isMobileBrowser()
        // 如果是桌面扫码进来，没有flag字段，要进行websocket连接，如果从遥控主页进来则不需要再重新连接websocket
        // 刚进来先试连接一下扫码进来携带的url+:9998,如果连接成功，则进行发送接收信息
        // 如果没连接成功，像后端掉接口，判断出该url下的link，进行循环判断连接，直到websocket连接成功
        let api = sessionStorage.getItem('apiUrls') || ''
        if (!this.$route?.query?.flag && api?.length == 0 && this.$route.query?.url?.length > 0) {
            // let da = 'http://192.168.3.238:23577'
            this.urlName = this.$route.query?.url.split(':')[1]
            this.ws = 'ws:' + this.urlName + ':9998'
            this.param = {
                cmd: 3,
                data: {
                    system: false,
                },
            };
            this.wsList = [this.ws]
        }
    },
    methods: {
        // 刚进来首先试连接websocket ，将能连接的一个地址筛选出来，然后在app.vue 中进行连接
        socket() {
            let socket = new WebSocket(this.wsList[this.num]);
            this.timer = setTimeout(() => {
                if (socket && socket.readyState === 0) {
                    socket.close(3000, "处理webSocket超时!");
                }
            }, 3000);
            socket.onopen = (e) => {
                clearTimeout(this.timer);
                sessionStorage.setItem('apiUrls', JSON.stringify([this.wsList[this.num]]))
                socket && socket.close(3000, "客户端主动关闭");
            };
            // //socket连接失败，断开连接，将此WS地址状态设为 -1，代表不可用
            socket.onerror = (e) => {
                clearTimeout(this.timer);
            };
            socket.onclose = (e) => {
                this.setItem()
            };
        },
        // 获取所有的websocket地址进行筛选
        getWsUrl() {
            let that = this
            post("/push/web/equipment/list", {}).then((res) => {
                if (res.code === 200) {
                    let num = -1
                    let content = ''
                    that.wsList = []
                    if (res.data instanceof Array && res.data.length > 0) {
                        let val = res.data.filter(it => that.ws.includes(it.hostIp));
                        if (val?.length > 0) {
                            val[0].extra.forEach((item => {
                                that.wsList.push(item.link)
                            }))
                            // 判断是否有9998端口号的，如果有，放到第一次，如果没有，添加一个到第一位
                            that.wsList.forEach((item, ind) => {
                                if (item.includes('9998')) {
                                    num = ind
                                    content = item
                                }
                            })
                            if (num > -1) {
                                that.wsList.splice(num, 1)
                                that.wsList.splice(0, 0, content)
                            } else {
                                that.wsList.splice(0, 0, that.ws)
                            }
                            // sessionStorage.setItem("apiUrls", JSON.stringify([...that.wsList]))
                            // 刚进来时，首先连接端口号为9998的，如果连接成功则成功，如果连接不成功则进行调用接口
                            // 如果接口中只有一条数据并且为9998的，直接提示连接异常，如果有多条数据，则循环进行连接
                            this.setItem()
                        } else {
                            this.$toast("连接设备，请检查手机与设备是否在同一局域网内");
                            this.$refs[this.isMobile].noUrl()
                        }
                    } else {
                        //未查询到设备
                        this.$toast("搜索设备失败，请检查手机与设备是否在同一局域网内");
                        this.$refs[this.isMobile].noUrl()
                    }
                }
                this.flag = true
            }).catch((err) => {
                console.log(err, "err");
                Toast.clear();
                this.flag = true
            });
        },
        connected() {
        },
        onOpen() {
            this.num = 0
        },
        onError() {
        },
        onMessage(val) {
            this.$refs[this.isMobile].onMessage(val, true)
        },
        // 判断是手机浏览器还是电脑浏览器
        isMobileBrowser() {
            let userAgent = navigator.userAgent || navigator.vendor || window.opera;
            // 在用户代理字符串中搜索特定的手机浏览器标识符
            return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
        },
        // 连接websocket -- 从桌面进来 将能连接的link进行从前往后试连接，成功即停止
        setItem() {
            // 从本次获取一下apiUrls ，如果存的有值，表示上边有连接成功的，所以直接在app中直接连接
            let url = sessionStorage.getItem('apiUrls')
            if (url?.length > 0) {
                this.getInstall()
            } else {
                if (this.flag && !this.$route.query.flag) {
                    this.num++
                    if (this.num < this.wsList.length) {
                        this.ws = this.wsList[this.num]
                        this.socket()
                    } else {
                        this.errorMsg()
                        // 如果试连接websocket失败，默认将应用loading关闭
                        this.$refs[this.isMobile].loading = false

                    }
                } else {
                    this.getWsUrl()
                }
            }

        },
        close() {
            Toast.clear()
            this.errorMsg()
            // 如果连接websocket失败，默认将应用loading关闭
            this.$refs[this.isMobile].loading = false
        },
        errorMsg() {
            let mes = this.$route.query.flag == 'true' ? '请返回遥控首页重新连接设备' : '请重新扫码连接设备'
            Toast.fail(`当前设备状态异常，${mes}`);
        },
        // 向客户端获取安装过的应用
        getInstall() {
            let param = {
                cmd: 3,
                data: {
                    system: false,
                },
            };

            this.$emit('connectSocket', 0, param)
        },
        // 点击下载&安装&更新&打开等按钮
        sendSocket(param) {
            this.$emit("sendSocket", param);
        }
    }
}
</script>
<style lang="less" scoped>
.uploads {
    background: #F2F3F5;
    min-height: 100vh;

    /deep/ .van-uploader {
        width: 100%;
    }

    /deep/ .van-uploader__upload {
        background-color: rgba(0, 0, 0, 0) !important;
        width: 100%;
    }

}
</style>
