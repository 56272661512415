<template>
    <div class="upload_ph">
        <!-- 上传 -->
        <div class="ph_top" :style="{ height: fileList.length > 0 ? '19vh' : '60vh' }">
            <div class="length">
                <van-uploader v-model="fileList" accept="" :preview-image="false"
                    :before-read="file => beforeRead(file, true)"
                    :after-read="(file, detail) => afterRead(file, detail, true)">
                    <div class="type">
                        <p style="height:5vh" v-if="fileList.length == 0" />
                        <div class="upload_img">
                            <img src="@/assets/upload_tip.png"
                                :style="{ width: fileList.length == 0 ? '53px' : '42px' }" />
                            <p>点击区域选择文件上传</p>
                        </div>
                        <div class="type_none" v-if="fileList.length == 0">
                            <p style="line-height: 4vh;"> 支持传输文件格式：</p>
                            <p>.apk、.png、.jpg、.mp4、.doc、.docx、.xlsx、.ppt、.pdf</p>
                            <p class="type_more">更多格式正在增加中…</p>
                        </div>
                        <div v-else style="font-size:3vw;margin-bottom: 2.8vh;">
                            <span>支持格式：</span>
                            <span>.apk、.png、.jpg、.mp4、.doc、.docx、.xlsx、.ppt、.pdf</span>
                        </div>
                    </div>
                </van-uploader>
            </div>
        </div>
        <!-- 提示语 -->
        <div class="tip" v-if="fileList.length > 0">
            <img src="@/assets/warn_tip.png" />
            <span>上传过程不能退出应用,手机或电脑与电视应保持在同一局域网内！</span>
        </div>
        <!-- 上传列表 -->
        <div class="fileList" v-if="fileList.length > 0">
            <div v-for="(item, index) in fileList" :key="index" class="file_item">
                <img src="@/assets/fail.png" class="type_img" v-if="item.status == 'failed'" />
                <div v-if="item.status != 'failed'">
                    <img src="@/assets/apk_upload.png" class="type_img" v-if="item.file.name.includes('.apk')" />
                    <img src="@/assets/doc_upload.png" class="type_img" v-if="item.file.name.includes('.doc')" />
                    <img src="@/assets/img_upload.png" class="type_img" v-if="item.file.type.includes('image')" />
                    <img src="@/assets/mp_upload.png" class="type_img" v-if="item.file.name.includes('.mp4')" />
                    <img src="@/assets/pdf_upload.png" class="type_img" v-if="item.file.name.includes('.pdf')" />
                    <img src="@/assets/xlsx_upload.png" class="type_img" v-if="item.file.name.includes('.xlsx')" />
                    <img src="@/assets/ppt_upload.png" class="type_img"
                        v-if="item.file.name.includes('.ppt') || item.file.name.includes('.pptx')" />
                </div>
                <div class="upload_right">
                    <div class="name">
                        <p :style="{ color: item.status == 'failed' ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0.8)' }"
                            class="name_top">{{
                                item.file.name }}</p>
                        <span style="font-size:3.3vw;color: rgba(0,0,0,0.4);">{{ conversion(item.file.size) }}</span>
                    </div>
                    <p v-if="item.status == 'failed'">
                        <img src="@/assets/error_tip.png" />
                        <span class="words" style="color: rgba(0,0,0,0.8);">{{ item.type == 0 ? '上传失败，文件格式错误'
                            : item.type == 1 ? '上传失败，文件已存在' :
                                '上传失败，稍后再试'
                            }}</span>
                    </p>
                    <p v-if="item.status != 'failed' && (item.uploadPercent == 100 || item.uploadPercent == 0)"
                        class="no_fail">
                        <img src="@/assets/suc_tip.png" v-if="item.uploadPercent == 100" />
                        <img src="@/assets/wait.png" v-if="item.uploadPercent == 0" style="margin-top:0.2vw;" />
                        <span class="words">{{ item.uploadPercent == 0 ? '待上传' : '上传成功' }}</span>
                    </p>
                    <van-progress :percentage="item.uploadPercent" color="#177DDC" track-color="#E1E8F9"
                        :show-pivot="false" style="margin-top: 3px;"
                        v-if="item.status != 'failed' && item.uploadPercent != 100 && item.uploadPercent > 0" />
                </div>
            </div>
        </div>
        <!-- 应用列表 -->
        <div class="app_list" v-show="moveFlag == 0">
            <div class="type" v-if="menuList.length > 0">
                <div class="type_name" @click="getList(0)">
                    <p
                        :style="{ color: type == 0 ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)', fontSize: type == 0 ? '3.9vw' : '3.3vw' }">
                        {{
                            menuList[0].name }}</p>
                    <div class="line" :style="{ background: type == 0 ? '#4089F8' : '#F2F3F5' }" />
                </div>
                <div class="type_name" @click="getList(1)" v-if="menuList.length > 1">
                    <p
                        :style="{ color: type == 1 ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)', fontSize: type == 1 ? '3.9vw' : '3.3vw' }">
                        {{
                            menuList[1].name }}</p>
                    <div class="line" :style="{ background: type == 1 ? '#4089F8' : '#F2F3F5' }" />
                </div>
            </div>
            <div class="app_con" v-show="list.length > 0">
                <div class="title_out" v-if="classify == 4 && typeList.length > 0">
                    <div class="title">
                        <p class="title_item" v-for="(item, ind) in typeList" :key="ind" @click="typeChoose(item, ind)"
                            :style="{ marginLeft: ind == 0 ? '5vw' : '0', background: typeHot == ind ? '#E7EFFF' : '#EFF0F1', color: typeHot == ind ? '#447EF1' : 'rgba(0,0,0,0.6)' }">
                            {{ item.name }}</p>
                    </div>
                </div>
                <div v-if="loading" style="text-align: center;height:45vw;line-height: 45vw;">
                    <van-loading size="24px">加载中...</van-loading>
                </div>
                <div class="apps" v-if="list.length > 0 && !loading">
                    <div class="items" v-for="(val, index) in list" :key="index" v-if="index < 5">
                        <img :src="val.icon" width="60vw" />
                        <p class="name">{{ val.name }}</p>
                        <div style="height:5vw">
                            <div class="state " v-if="val.state != 1" @click="updateClick(val, true)" :style="{
                                color: buttonText(val.state, 1), background: buttonText(val.state, 2),
                                border: val.state == 2 ? '1px solid #4284F5' : 'none',
                            }">
                                {{ buttonText(val.state, 0) }}
                            </div>
                            <div v-if="val.state == 1" class="state stateDown">
                                <van-progress :percentage="val.describe" color="#177DDC" track-color="#E1E8F9"
                                    :show-pivot="false" />
                                <p>{{ `${val.describe}%` }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="more_app" @click="goMore" v-show="list.length > 5 && !loading">
                    <span>查看更多</span>
                    <img src="@/assets/more.png" />
                </div>
            </div>
        </div>
        <!-- 移动按钮 -->
        <div>
            <div class="move" v-show="moveFlag != 0"
                :style="{ height: '11vw', width: moveFlag == 2 ? '11vw' : '30vw', left: left + 'px', top: top + 'px' }"
                ref="move" @touchstart.stop.prevent="(e) => { dragStart(e) }"
                @touchend.stop.prevent="(e) => { dragEnd(e) }" @touchmove.stop.prevent="(e) => { dragProgress(e) }">
                <img src="@/assets/move_tip.png" :style="{ marginRight: moveFlag == 1 ? '2vw' : '0' }" />
                <p v-if="moveFlag == 1">推荐应用</p>
            </div>
        </div>
        <van-popup v-model:show="show" position="bottom"
            :style="{ height: type == 0 ? '30vh' : '35vh', borderRadius: '4vw 4vw 0 0', }">
            <img src="@/assets/close_tip.png" style="position: absolute;
            top:2.3vw;right:2.8vw;width:6.5vw" @click="show = false" />
            <div class="app_list ">
                <div class="type" style="justify-content: flex-start;padding:3vw 2vw 0;" v-if="menuList.length > 0">
                    <div class="type_name" @click="getList(0)" style="margin-left: 2vw">
                        <p
                            :style="{ color: type == 0 ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)', fontSize: type == 0 ? '3.9vw' : '3.3vw' }">
                            {{
                                menuList[0].name }}</p>
                        <div class="line" :style="{ background: type == 0 ? '#4089F8' : '#fff' }" />
                    </div>
                    <div class="type_name" @click="getList(1)" v-if="menuList.length > 1">
                        <p
                            :style="{ color: type == 1 ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)', fontSize: type == 1 ? '3.9vw' : '3.3vw' }">
                            {{
                                menuList[1].name }}</p>
                        <div class="line" :style="{ background: type == 1 ? '#4089F8' : '#fff' }" />
                    </div>
                </div>
                <div class="app_con">
                    <div class="title_out" v-if="classify == 4 && typeList.length > 0">
                        <div class="title">
                            <p class="title_item" v-for="(item, ind) in typeList" :key="ind"
                                @click="typeChoose(item, ind)"
                                :style="{ marginLeft: ind == 0 ? '5vw' : '0', background: typeHot == ind ? '#E7EFFF' : '#EFF0F1', color: typeHot == ind ? '#447EF1' : 'rgba(0,0,0,0.6)' }">
                                {{ item.name }}</p>
                        </div>
                    </div>
                    <div v-if="loading" style="text-align: center;height:45vw;line-height: 45vw;">
                        <van-loading size="24px">加载中...</van-loading>
                    </div>

                    <div class="apps" v-if="list.length > 0 && !loading">
                        <div class="items" v-for="(val, index) in list" :key="index" v-if="index < 5">
                            <img :src="val.icon" width="57vw" />
                            <p class="name">{{ val.name }}</p>
                            <div style="height:4vw">
                                <div class="state" v-if="val.state != 1" @click="updateClick(val, true)" :style="{
                                    color: buttonText(val.state, 1), background: buttonText(val.state, 2),
                                    border: val.state == 2 ? '1px solid #4284F5' : 'none',
                                }">
                                    {{ buttonText(val.state, 0) }}
                                </div>
                                <div v-if="val.state == 1" class="state stateDown">
                                    <van-progress :percentage="val.describe" color="#177DDC" track-color="#E1E8F9"
                                        :show-pivot="false" />
                                    <p>{{ `${val.describe}%` }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="more_app" @click="goMore" v-show="list.length > 5 && !loading"
                        style="margin-top: 3.5vh;">
                        <span>查看更多</span>
                        <img src="@/assets/more.png" />
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import { Toast } from "vant";
import axios from "axios";
import { Popup } from 'vant';
import pushJs from '@/mixins/push.js'
import commonJs from '@/mixins/common.js'
export default {
    mixins: [pushJs, commonJs],
    data() {
        return {
            loading: false,
            show: false,
            uploadUrl: '',
            fileList: [],
            num: [],
            active: 0,
            typeHot: 0, // 热门类别分类
            type: 0, // 装机必备&热门类别,
            list: [],// 应用数据列表
            moveFlag: 0,// 移动按钮显示 0 显示应用列表  1 显示移动按钮(带字的) 2显示移动按钮(不带字)
            uploadPercent: '',//上传文件百分比
            appList: [],// 应用列表
            menuList: [],
            typeList: [], // 热门分类列表数据
            appUrl: '',// 获取地址
            classify: 0,
            moveSign: false
        }
    },
    created() {
        this.uploadUrl = this.$route.query?.url
        // this.uploadUrl = 'http://192.168.3.238:23577'
        this.getMenuList(true)
    },

    methods: {
        //按钮字体、颜色、背景
        buttonText(state, sign) {
            switch (state) {
                case 0:
                    return sign == 0 ? "下载" : sign == 1 ? '#FFFFFF' : '#4E86FF';

                case 2:
                    return sign == 0 ? "打开" : sign == 1 ? '#4285F5' : '#FFFFFF';

                case 3:
                    return sign == 0 ? "更新" : sign == 1 ? '#FFFFFF' : '#FF692E';

                case 4:
                    return sign == 0 ? "安装" : sign == 1 ? '#4482F4' : '#E7EFFF';
            }
        },

    },
    mounted() { },
}
</script>
<style lang="less" scoped>
.upload_ph {
    width: 100vw;


    .ph_top {
        width: 100%;
        background: linear-gradient(135deg, #3D94FF 0%, #457CF0 100%);

        .van-uploader {
            height: 100%;

            /deep/.van-uploader__wrapper {
                height: 100%;
                width: 100%;

                .van-uploader__input-wrapper {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .length {
            width: 100%;
            height: 100%;

            .type {
                color: #fff;
                font-size: 3.4vw;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;

                .upload_img {
                    text-align: center;
                    margin-top: 3vh;

                    img {
                        margin-bottom: 1vh;
                    }
                }

                .type_more {
                    color: rgba(255, 255, 255, 0.7);
                    font-size: 3.3vw;
                    text-align: center;
                    margin: 3vh 0 4vh;
                }
            }
        }

    }

    .tip {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 3.2vw;
        color: #E8840B;
        background-color: #FFE9C4;
        line-height: 4vh;
        margin: 0.5vh 0 1vh;

        img {
            width: 2.8vw;
            margin: 0 1.5vw 0 1vw;
        }
    }

    .fileList {
        width: 100%;
        max-height: 75vh;
        overflow: auto;

        .file_item {
            margin: 1.5vw 1.5vw 0;
            background-color: #fff;
            border-radius: 2vw;
            padding: 1.9vw 3.5vw 1.9vw 2vw;
            display: flex;
            justify-content: space-between;
            color: rgba(0, 0, 0, 0.8);
            font-size: 3.8vw;
            align-items: center;

            .type_img {
                width: 10vw;
            }

            .upload_right {
                width: 80vw;

                .name {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0.2vw;

                    .name_top {
                        max-width: 80%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                }

                img {
                    width: 3.3vw;
                    margin-right: 1vw;
                }

                .words {
                    font-size: 3.2vw;
                    color: rgba(0, 0, 0, 0.5);
                }

                .no_fail {
                    display: flex;
                    align-items: center;
                }
            }
        }

        /deep/ .van-progress {
            margin-top: 1vh !important;
            height: 0.8vh;
            background-color: #F0F0F0 !important;
        }

        /deep/ .van-progress__portion {
            background: #4189F9 !important;
        }
    }

    .app_list {
        width: 100%;

        .type {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2.8vh 0 2vh;


            .type_name {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                margin-right: 7vw;
                cursor: pointer;

                .line {
                    width: 4vw;
                    height: 1.03vw;
                    background: #4089F8;
                    border-radius: 0.5vw;
                    margin-top: 5px;
                }
            }
        }

        .app_con {
            background-color: #fff;
            padding: 4vw 0 6vw;

            .title_out {
                height: 9vw;
                overflow: hidden;

                .title {
                    display: flex;
                    align-items: center;
                    padding: 0 0 30vw;
                    width: 100%;
                    overflow-x: auto;

                    .title_item {
                        color: rgba(0, 0, 0, 0.6);
                        font-size: 3.6vw;
                        padding: 0.8vw 4.5vw;
                        border-radius: 2.5vw;
                        margin: 0 5vw 1vw 0;
                        white-space: nowrap;
                    }
                }
            }

            .apps {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                font-size: 3.4vw;

                .items {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .name {
                        max-width: 16vw;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin: 0.8vh 0 1.2vh;
                        color: rgba(0, 0, 0, 0.8);
                    }

                    .state {
                        height: 6.5vw;
                        line-height: 6.5vw;
                        width: 16vw;
                        text-align: center;
                        border-radius: 3vw;
                        font-size: 3.6vw;
                    }

                    .stateDown {
                        overflow: hidden;
                        position: relative;
                        border: 1px solid #4284F5;

                        .van-progress {
                            height: 100%;
                            border-radius: 4vw;
                            background-color: #E7EFFF !important;
                        }

                        /deep/.van-progress__portion {
                            border-radius: 0;
                            background-color: #A4BFFF !important;
                        }

                        p {
                            position: absolute;
                            top: 0;
                            left: 52%;
                            transform: translate(-50%);
                            color: #4383F4;
                        }
                    }
                }
            }

            .more_app {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 3.6vw;
                color: #4382F4;
                margin-top: 6vw;

                img {
                    width: 3.3vw;
                    margin-left: 1vw;
                }
            }
        }
    }

    .move {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(180deg, #76A7FF 0%, #508DFF 79%, #327AFF 100%);
        box-shadow: 0px 2px 4px 0px #9EC0FF;
        border-radius: 10vh;
        font-size: 3.6vw;
        color: #fff;
        overflow: hidden;


        img {
            width: 4.2vw;
        }
    }

    .pop_tab {
        display: flex;
        font-size: 3.6vw;
        color: rgba(0, 0, 0, 0.5);
        padding: 4vw 0 0;
        align-items: center;
        width: 100%;
        margin: 0;

        .tab_item {
            margin: 0 3vw;
        }

        .more_app {
            padding: 2.5vh 0 0;
        }
    }
}
</style>