<template>
    <div class="recommend">
        <listPhone v-if="isMobile" @getInstall="getInstall" @sendSocket="sendSocket" ref="true" />
        <listPc v-else @getInstall="getInstall" @sendSocket="sendSocket" ref="false" />
    </div>
</template>
<script>
import listPc from './listPc.vue'
import listPhone from './listPhone.vue'
import { Toast } from 'vant';
export default {
    components: { listPc, listPhone },
    data() {
        return {
            isMobile: true, // 是否是手机浏览器 true是，false不是
        }
    },
    created() {
        this.isMobile = this.isMobileBrowser()
    },
    methods: {
        connected() { },
        onOpen() {
        },
        onError() {
            this.$refs[this.isMobile].loading = false
        },
        onMessage(val) {
            this.$refs[this.isMobile].onMessage(val, true)
        },
        close() {
            Toast.clear()
            let mes = this.$route.query.flag == 'true' ? '请返回遥控首页重新连接设备' : '请重新扫码连接设备'
            Toast.fail(`当前设备状态异常，${mes}`);
            this.$refs[this.isMobile].loading = false
        },
        // 向客户端获取安装过的应用
        getInstall() {
            let param = {
                cmd: 3,
                data: {
                    system: false,
                },
            };
            this.$emit('connectSocket', 0, param, true)
        },
        sendSocket(param) {
            this.$emit("sendSocket", param);
        },
        // 判断是手机浏览器还是电脑浏览器
        isMobileBrowser() {
            let userAgent = navigator.userAgent || navigator.vendor || window.opera;
            // 在用户代理字符串中搜索特定的手机浏览器标识符
            return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
        },
    }
}
</script>
<style lang="less" scoped>
.recommend {}
</style>