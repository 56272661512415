<template>
    <div style="background-color: #F5F5F5;min-height:100vh">
        <div class="list_ph">
            <div class="type" v-if="menuList.length > 0">
                <div class="type_name" @click="getList(0)">
                    <p
                        :style="{ color: type == 0 ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)', fontSize: type == 0 ? '3.9vw' : '3.3vw' }">
                        {{
                menuList[0].name }}</p>
                    <div class="line" :style="{ background: type == 0 ? '#4089F8' : '#FAFAFA' }" />
                </div>
                <div class="type_name" @click="getList(1)" v-if="menuList.length > 1">
                    <p
                        :style="{ color: type == 1 ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)', fontSize: type == 1 ? '3.9vw' : '3.3vw' }">
                        {{
                menuList[1].name }}</p>
                    <div class="line" :style="{ background: type == 1 ? '#4089F8' : '#FAFAFA' }" />
                </div>
            </div>
            <div class="title" v-if="classify == 4 && typeList.length > 0">
                <p class="title_item" v-for="(item, ind) in typeList" :key="ind" @click="typeChoose(item, ind)"
                    :style="{ background: typeHot == ind ? '#E7EFFF' : '#EFF0F1', color: typeHot == ind ? '#447EF1' : 'rgba(0,0,0,0.6)' }">
                    {{ item.name }}</p>
            </div>

            <div class="appList" v-if="list.length > 0 && !loading">
                <div class="app_item" v-for="(val, ind) in list" :key="ind">
                    <div class="item_left">
                        <img :src="val.icon" />
                        <div class="con">
                            <p class="name">{{ val.name }}</p>
                            <p>{{ `${val.verCode} | ${val.apkSize}` }}</p>
                        </div>
                    </div>
                    <div class="state" v-if="val.state != 1" @click="updateClick(val, true)" :style="{
                color: buttonText(val.state, 1), background: buttonText(val.state, 2),
                border: val.state == 2 ? '1px solid #4284F5' : 'none',
            }">
                        {{ buttonText(val.state, 0) }}
                    </div>
                    <div v-if="val.state == 1" class="stateDown" style=" width: 16vw;height: 6.5vw;">
                        <van-progress :percentage="val.describe" :show-pivot="false" />
                        <p>{{ `${val.describe}%` }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loading" style="text-align: center;margin-top: 45vw;">
            <van-loading size="24px">加载中...</van-loading>
        </div>
        <div class="none_data" v-if="!loading">
            <div class="line" style="background: linear-gradient(90deg, rgba(239, 239, 239, 0) 0%, #DBDBDB 100%);" />
            <p>没有更多了</p>
            <div class="line"
                style="background: linear-gradient( 90deg, rgba(239,239,239,0) 0%, #DBDBDB 100%);transform: rotateY(180deg);" />
        </div>
    </div>
</template>
<script>
import { Popup, Toast } from 'vant';
import commonJs from '@/mixins/common.js'
import recommendJs from '@/mixins/recommend.js'
export default {
    mixins: [commonJs, recommendJs],
    data() {
        return {
            loading: false,
            type: 0,
            menuList: [], // 热门分类列表数据
            typeList: [],
            typeHot: 0,
            list: [],// 应用数据列表
            classify: 0
        }
    },
    created() {
        this.getMenuList(true)
    },
    methods: {

        //按钮字体、颜色、背景
        buttonText(state, sign) {
            switch (state) {
                case 0:
                    return sign == 0 ? "下载" : sign == 1 ? '#FFFFFF' : '#4E86FF';
                case 2:
                    return sign == 0 ? "打开" : sign == 1 ? '#4285F5' : '#FFFFFF';
                case 3:
                    return sign == 0 ? "更新" : sign == 1 ? '#FFFFFF' : '#FF692E';
                case 4:
                    return sign == 0 ? "安装" : sign == 1 ? '#4482F4' : '#E7EFFF';
            }
        },

    }
}
</script>
<style lang="less" scoped>
.list_ph {
    background: #fff;
    padding: 0 2.5vw 2.5vw;

    .type {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 2vh;

        .type_name {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin-right: 7vw;
            cursor: pointer;

            .line {
                width: 4vw;
                height: 1.03vw;
                background: #4089F8;
                border-radius: 0.5vw;
                margin-top: 5px;
            }
        }
    }

    .title {
        display: flex;
        align-items: center;
        padding: 4vw 0 0;
        width: 100%;
        overflow-x: auto;

        .title_item {
            color: rgba(0, 0, 0, 0.6);
            font-size: 3.6vw;
            padding: 0.6vw 4.6vw;
            border-radius: 2vw;
            margin: 0 3.4vw;
            white-space: nowrap;
        }
    }

    .appList {
        margin-top: 3vh;

        .app_item {
            margin-bottom: 2vh;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 5.6vh;

            .item_left {
                display: flex;
                align-items: center;

                img {
                    width: 12vw;
                    margin-right: 2vw;
                }

                .con {
                    color: rgba(0, 0, 0, 0.5);
                    font-size: 3.3vw;

                    .name {
                        color: rgba(0, 0, 0, 0.8);
                        font-size: 3.7vw;
                        font-weight: 500;
                        margin-bottom: 1vw;
                        max-width: 50vw;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .state {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 3.6vw;
                height: 6.5vw;
                width: 16vw;
                border-radius: 3vw;
            }

            .stateDown {
                border-radius: 3vw;
                overflow: hidden;
                height: 6.5vw;
                width: 16vw;
                overflow: hidden;
                position: relative;
                border: 1px solid #4284F5;
                font-size: 3.6vw;

                /deep/.van-progress {
                    height: 100%;
                    border-radius: 3vw;
                    background-color: #E7EFFF !important;
                }

                /deep/.van-progress__portion {
                    border-radius: 0;
                    background-color: #A4BFFF !important;
                }

                p {
                    position: absolute;
                    top: 10%;
                    left: 53%;
                    transform: translate(-50%);
                    color: #4383F4;
                }
            }

        }
    }


}

.none_data {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.3vw;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 2vh;

    .line {
        width: 14vw;
        height: 0.5vw;
        border-radius: 1px;
        margin: 0 1.5vw;
    }
}
</style>