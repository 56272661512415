import axios from "axios";
import { Toast } from "vant";
import { get } from "@/utils/request";
export default {
  watch: {
    fileList() {
      //监听fileList数组，当用户新上传文件时，遍历数组往里加一个uploadPercent属性来记录上传百分比
      this.fileList.forEach((item, index) => {
        if (!item.uploadPercent) {
          this.$set(this.fileList[index], "uploadPercent", 0);
        }
      });
    },
  },
  data() {
    return {
      left: 0,
      top: 0,
      itemWidth: 100, // 图标的宽度
      itemHeight: 60,
      clientWidth: null,
      clientHeight: null,
    };
  },
  created() {},

  methods: {
    beforeRead(file, sign) {
      // sign true 手机端  false pc端 手机端会Toast提示
      if (!this.$parent.isMobile && Array.isArray(file) && file?.length > 20) {
        Toast.fail("单次最多上传20个文件");
        return false;
      } else {
        let reg =
          /\.(apk|apk.1|apk.1.1|apk.1.1.1|apk.1.1.1.1|PNG|JPG|apk.1.1.1.1.1|png|jpg|xlsx|jpeg|pdf|mp4|docx|doc|ppt|pptx)$/;
        //判断文件类型是否为.APK格式后缀
        let size = 0;
        let flag = 0;
        if (file?.length > 0) {
          file.forEach((item) => {
            !reg.test(item.name) ? flag++ : "";
            size = size + item.size;
          });
        } else {
          !reg.test(file.name) ? flag++ : "";
          size = file.size;
        }
        let mb = size / 1024 / 1024;
        if (mb > 500) {
          Toast.fail("上传文件过大，请上传总大小在500MB以内的文件");
          return false;
        } else {
          sign && flag > 0 ? Toast.fail("该文件不支持上传") : "";
          return true;
        }
      }
    },
    afterRead(file, detail, sign) {
      // 将新上传的数据放到最前面
      let num = Array.isArray(file) ? detail.index : this.fileList?.length - 1;
      this.fileList.splice(num);
      let arr = Array.isArray(file) ? [...file] : [file];
      this.fileList.unshift(...arr);
      // sign true 手机端 需要Toast提示
      //  文件上传时 pc端增加了多文件上传，file是个数组，所以要区分处理
      let reg =
        /\.(apk|apk.1|apk.1.1|apk.1.1.1|apk.1.1.1.1|PNG|JPG|apk.1.1.1.1.1|png|jpg|xlsx|jpeg|pdf|mp4|docx|doc|ppt|pptx)$/;
      if (file?.length > 0) {
        file.forEach((item, ind) => {
          if (reg.test(item.file.name)) {
            let num = 0;
            this.fileList.forEach((val) => {
              let sign =
                item.file.name == val.file.name && val.status != "failed";
              sign ? num++ : num;
            });
            if (num > 1) {
              this.$set(this.fileList[ind], "status", "failed");
              this.$set(this.fileList[ind], "type", 1); // type 为0时，格式错误  1 文件已存在
              this.$set(item, "status", "failed");
              this.$set(item, "type", 1); // type 为true时，格式错误
            } else {
              this.updateAxios(item, ind, sign);
            }
          } else {
            this.$set(this.fileList[ind], "status", "failed");
            this.$set(this.fileList[ind], "type", 0); // type 为0时，格式错误  1 文件已存在
            this.$set(item, "status", "failed");
            this.$set(item, "type", 0); // type 为true时，格式错误
          }
        });
      } else {
        if (reg.test(file.file.name)) {
          let num = 0;
          this.fileList.forEach((val) => {
            let flag =
              file.file.name == val.file.name && val.status != "failed";
            flag ? num++ : num;
          });
          if (num > 1) {
            this.$set(this.fileList[0], "status", "failed");
            this.$set(this.fileList[0], "type", 1); // type 为0时，格式错误  1 文件已存在
            this.$set(file, "status", "failed");
            this.$set(file, "type", 1); // type 为true时，格式错误
          } else {
            this.updateAxios(file, 0, sign);
          }
        } else {
          this.$set(this.fileList[0], "status", "failed");
          this.$set(this.fileList[0], "type", 0); // type 为0时，格式错误
          this.$set(file, "status", "failed");
          this.$set(file, "type", 0); // type 为0时，格式错误
        }
      }
      if (this.$parent.isMobile) {
        this.$nextTick(() => {
          this.height();
        });
      }
    },
    // 文件上传接口处理
    updateAxios(file, ind, sign) {
      const that = this;
      let formData = new FormData();
      formData.append("file", file.file, encodeURI(file.file.name));
      const headers = { "File-Name": encodeURI(file.file.name) };
      axios
        .post(`${this.uploadUrl}/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "File-Name": encodeURI(file.file.name),
          },
          //监听上传进度回调
          onUploadProgress: (progressEvent, files) => {
            if (progressEvent.lengthComputable) {
              let percent = (progressEvent.loaded / progressEvent.total) * 100;
              let i = 0;
              that.fileList.forEach((item, index) => {
                if (
                  file.file.name == item.file.name &&
                  file.status != "failed"
                ) {
                  i = index;
                }
              });

              //计算上传百分比
              that.$set(that.fileList[i], "uploadPercent", percent);
            }
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            sign ? Toast.success(res.data.msg) : "";
          } else {
            sign ? Toast(res.data.msg) : "";
          }
        })
        .catch((err) => {
          sign ? Toast.fail("上传失败") : "";
          this.$set(that.fileList[ind], "status", "failed");
          this.$set(that.fileList[ind], "type", 2); // type 为true时，格式错误
          this.$set(file, "type", 2); // type 为true时，格式错误
          this.$set(file, "status", "failed");
        });
    },
    // 上传文件大小转化
    conversion(limit) {
      var size = "";
      if (limit < 0.1 * 1024) {
        //小于0.1KB，则转化成B
        size = limit.toFixed(2) + "B";
      } else if (limit < 0.1 * 1024 * 1024) {
        //小于0.1MB，则转化成KB
        size = (limit / 1024).toFixed(2) + "KB";
      } else if (limit < 1 * 1024 * 1024 * 1024) {
        //小于1GB，则转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + "MB";
      }

      var sizeStr = size + ""; //转成字符串
      var index = sizeStr.indexOf("."); //获取小数点处的索引
      var dou = sizeStr.substr(index + 1, 2); //获取小数点后两位的值
      if (dou == "00") {
        //判断后两位是否为00，如果是则删除00
        return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
      }
      return size;
    },
    // 切换热门类型下所有分类
    typeChoose(item, ind) {
      if (this.typeHot != ind) {
        this.typeHot = ind;
        this.getAppList(item.id);
      }
    },
    getAppList(val) {
      this.loading = true;
      let that = this;
      let params = {
        typeId: val,
        size: 200,
        current: 1,
      };
      get("/push/type/app/query", params)
        .then((res) => {
          if (res.code == 200) {
            that.list = res.data;
            that.list.forEach((item) => {
              that.$set(item, "state", 0);
            });
            that.$route.query.flag == "true"
              ? that.$emit("getInstall")
              : that.$emit("setItem");
          }
        })
        .catch((err) => {
          this.loading = false;
          Toast.clear();
        });
    },
    // 获取分类&装机必备等菜单栏
    getMenuList(flag) {
      let that = this;
      let params = {
        menu: flag ? true : false,
        size: 200,
        current: 1,
        type: 1,
        classify: flag ? "" : 4,
      };
      get(`/push/type/query`, params)
        .then((res) => {
          if (res?.code === 200) {
            flag
              ? (that.menuList = res.data.records)
              : (that.typeList = res.data.records);
            if (flag && res.data.records[0].classify == 4) {
              that.getMenuList(false);
            } else {
              that.classify = that.menuList[that.type].classify;
              let id =
                that.classify == 4
                  ? that.typeList[that.typeHot].id
                  : res.data.records[0].id;
              that.getAppList(id);
            }
          }
        })
        .catch((err) => {
          Toast.clear();
        });
    },
    // 切换装机必备&热门类别
    getList(ind) {
      if (ind != this.type) {
        this.type = ind;
        let flag = ind == 0 ? true : false;
        this.classify = this.menuList[this.type].classify;
        this.getMenuList(flag);
      }
    },
    // 查看更多
    goMore() {
      this.$router.push({
        path: "/recommend",
        query: {
          type: this.type, // 装机必备&热门类别
          typeHot: this.typeHot,
          ind: this.type == 0 ? 0 : this.typeHot, // 如果是热门类别获取当前选中的热门类别下个分类
          flag: this.$route.query?.flag || false,
        },
      });
    },
    height() {
      const height =
        document.getElementsByClassName("upload_ph")[0].offsetHeight;
      const windowHeight = window.innerHeight;
      if (height > windowHeight) {
        this.moveFlag = 1;
        this.$nextTick(() => {
          this.itemWidth =
            document.getElementsByClassName("move")[0]?.offsetWidth;
          this.itemHeight =
            document.getElementsByClassName("move")[0]?.offsetHeight;
          this.clientWidth = document.documentElement?.clientWidth;
          this.clientHeight = document.documentElement?.clientHeight;
          this.left = (this.clientWidth - this.itemWidth) / 2;
          this.top = this.clientHeight - 70;
        });
      }
    },
    // 按钮拖动逻辑
    dragStart(e) {
      this.$refs.move.style.transition = "none";
    },
    dragEnd(e) {
      if (this.moveSing) {
        this.$refs.move.style.transition = "all 0.5s";
        if (this.clientHeight - this.top >= 100) {
          let item = document.getElementsByClassName("move")[0]?.offsetWidth;
          // 距离底部300px以外，判断他是偏左还是偏右，直接让他到旁边为小按钮状态
          if (this.left > (this.clientWidth - item) / 2) {
            this.left = this.clientWidth - this.clientWidth * 0.11;
          } else {
            this.left = 0;
          }
          // 如果拖动到屏幕上边并超出屏幕this.top<0，吸附在最上边即可
          this.top = this.top < 0 ? 0 : this.top;
          this.moveFlag = 2;
        } else if (this.clientHeight - this.top < 100) {
          // 在底部300px内 不靠左右两边
          this.moveFlag = 1;
          // 距离顶部的距离固定 ，左右根据拖动来
          this.top = this.clientHeight - 70;
          // 靠近左边 10
          if (this.left < 10) {
            this.left = 0;
            this.moveFlag = 2;
          }
          // 靠近右边 10
          if (this.left > this.clientWidth - this.itemWidth - 10) {
            this.moveFlag = 2;
            this.$nextTick(() => {
              this.left = this.clientWidth - this.clientWidth * 0.11;
            });
          }
        }
      } else {
        this.show = true;
      }
      this.moveSing = false;
    },
    dragProgress(e) {
      this.moveSing = true;
      if (e.targetTouches.length === 1) {
        let touch = event.targetTouches[0];
        this.left = touch.clientX - this.itemWidth / 2;
        this.top = touch.clientY - this.itemHeight / 2;
      }
    },
    noUrl() {
      this.loading = false;
    },
  },
};
