<template>
    <div class="list_pc">
        <div class="top">
            <div class="title">
                <img src="@/assets/icon.png" width="20px" style="margin-right: 10px;" />
                <p>远程推送</p>
            </div>
        </div>
        <div class="content">
            <div style="margin: 15px 0" v-if="menuList.length > 0">
                <span>{{ `远程推送 > ${menuList[type].name}` }}</span>
            </div>
            <p style="font-size:16px;font-weight: 500;" v-if="menuList.length > 0">{{ menuList[type].name }}</p>
            <div class="type_hot" v-if="typeList.length > 0 && menuList[type].classify == 4">
                <div class="title_item" v-for="(item, ind) in typeList" :key="ind"
                    :style="{ color: typeHot == ind ? '#2F6FFD' : 'rgba(0,0,0,0.6)' }">
                    <p class="line_left">|</p>
                    <p @click="typeChoose(item, ind)" style="cursor: pointer;">{{ item.name }}</p>
                </div>
            </div>
            <div
                :style="{ height: typeList.length > 0 ? 'calc(100vh - 160px)' : 'calc(100vh - 130px)', overflowY: 'auto' }">
                <div class="appList" v-if="list.length > 0 && !loading">
                    <div class="item" v-for="(item, ind) in list" :key="ind"
                        :style="{ paddingBottom: item.state == 1 ? '0px' : '15px' }">
                        <div class="item_con">
                            <div class="item_left">
                                <img :src="item.icon" />
                                <div class="appName">{{ item.name }}</div>
                            </div>
                            <div class="item_right" @click="updateClick(item, true)">
                                <img src="@/assets/pc_down.png" v-if="item.state == 0" class="btn" />
                                <img src="@/assets/pc_install.png" v-if="item.state == 4" class="btn" />
                                <img src="@/assets/pc_on.png" v-if="item.state == 2" class="btn" />
                                <img src="@/assets/pc_update.png" v-if="item.state == 3" class="btn" />
                                <img src="@/assets/pc_load.png" v-if="item.state == 1" class="btn" />
                            </div>
                        </div>
                        <div class="item_prog" v-if="item.state == 1">
                            <van-progress :percentage="item.describe" color="#177DDC" track-color="#E1E8F9"
                                :show-pivot="false" />
                            <span>{{ `${item.describe}%` }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="loading" style="text-align: center;margin-top: 200px;">
                    <van-loading size="24px">加载中...</van-loading>
                </div>

            </div>
        </div>
        <img ref="imgBack" @click="onClickLeft" class="backImg" src="@/assets/goback.png" />
    </div>
</template>
<script>
import commonJs from '@/mixins/common.js'
import recommendJs from '@/mixins/recommend.js'
export default {
    mixins: [commonJs, recommendJs],
    data() {
        return {
            loading: false,
            type: 0,
            menuList: [], // 热门分类列表数据
            typeList: [],
            typeHot: 0,
            list: [],// 应用数据列表
            classify: 0
        }
    },
    created() {
        this.getMenuList(true)
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        },
    }
}
</script>
<style lang="less" scoped>
.list_pc {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    min-width: 1050px;
    background: #F4F4F4;
    min-height: 100vh;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);

    .top {
        width: 100%;
        height: 50px;
        background: #3A76F8;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;

        .title {
            width: 940px;
            color: #fff;
            display: flex;
            align-items: center;

        }
    }

    .content {
        width: 940px;

        .type_hot {
            display: flex;
            align-items: center;
            margin: 12px 0 0;

            .title_item {
                display: flex;
                align-items: center;
                margin-right: 10px;

                .line_left {
                    color: rgba(0, 0, 0, 0.4);
                    margin: -2px 10px 0 0;
                    line-height: 0px;
                }
            }
        }

        .appList {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .item {
                width: 294px;
                background: #FFFFFF;
                box-shadow: 0px 2px 8px 0px rgba(5, 5, 5, 0.1045);
                border-radius: 4px 4px 4px 4px;
                margin: 10px 10px 5px 0;
                padding: 15px;
                min-width: 260px;

                .item_con {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    img {
                        width: 53px;
                        margin-right: 10px;
                    }

                    .item_left {
                        width: calc(100% - 90px);
                        display: flex;
                        align-items: center;
                        color: rgba(0, 0, 0, 0.8);
                    }

                    .appName {
                        width: calc(100% - 80px);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .btn {
                        width: 68px;
                        height: 28px;
                        cursor: pointer;
                    }
                }

                .item_prog {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    font-size: 12px;

                    /deep/ .van-progress {
                        width: calc(100% - 42px);
                        margin-right: 9px;
                    }
                }
            }
        }
    }

    .backImg {
        position: fixed;
        bottom: 100px;
        right: 80px;
        width: 60px;
    }
}
</style>