<template>
  <page @refresh="onRefresh">
    <div class="page-wrapper detail-wrapper">
      <div class="appDetail">
        <div class="icon-com">
          <img width="100%" v-if="curAppItem && curAppItem.icon" :src="curAppItem.icon" />
        </div>
        <div class="message">
          <p class="name ellipsis">
            {{ curAppItem && curAppItem.name }}
          </p>
          <p class="desc ellipsis">
            {{ curAppItem && curAppItem.apkSize }} 丨 版本:{{
    curAppItem && curAppItem.verName
  }}
          </p>
        </div>
      </div>

      <div class="imgcom">
        <van-swipe class="my-swipe" :autoplay="3000" :show-indicators="true" indicator-color="white" v-if="swipeList">
          <van-swipe-item v-for="(item, index) in swipeList" :key="index">
            <img :src="item.picUrl" class="swipeImg" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="function">
        <p class="title">功能介绍</p>
        <p class="desc">
          {{ curAppItem && curAppItem.apkExplain }}
        </p>
      </div>
      <img @click="onClickLeft" ref="imgBack" class="backImg" src="../assets/goback.png" alt="" />
      <template v-if="apiUrls.length > 0">
        <van-button v-if="curAppItem && curAppItem.state == 3" plain type="info" @click="updateClick()">更新</van-button>
        <van-button v-if="curAppItem && curAppItem.state == 4" plain type="info" @click="updateClick()">安装</van-button>
        <button v-if="curAppItem && curAppItem.state == 1" class="loadingButtons" @click="updateClick()">
          下载中...
        </button>
        <van-button v-if="curAppItem && curAppItem.state == 2" plain type="info" @click="updateClick()">打开</van-button>
        <van-button v-if="(curAppItem && curAppItem.state == 0) ||
    (curAppItem && !curAppItem.state)
    " plain type="info" @click="updateClick()">下载</van-button>
      </template>
      <template v-else>
        <van-button plain type="info" @click="noUrlDownLoad">下载</van-button>
      </template>
    </div>
    <selectPopup :submit="selectSubmit" ref="selectPopupRef" />
  </page>
</template>
<script>
import { post } from "@/utils/request";
import selectPopup from "@/components/selectPopup.vue";
import { Toast } from "vant";

export default {
  components: { selectPopup },
  data() {
    return {
      id: this.$route.query.id,
      curUrlIndex: 0,
      apiUrls: [],
      curAppItem: null,
      onOpenSet: null,
      timer: null,
    };
  },
  methods: {
    //itemEquipmentIndex-设备index,   curWsIndex-设备地址index
    handleEquipWS(data, itemEquipmentIndex, curWsIndex) {
      // debugger
      console.log('开始轮询')
      console.log(`当前设备index为${itemEquipmentIndex}, 地址index为${curWsIndex}`);
      let itemEquipment = data[itemEquipmentIndex]; // 单个设备信息
      let wsList = itemEquipment.extra;
      let socket = new WebSocket(wsList[curWsIndex].link);
      console.log(socket.url, "发起新的socket请求");
      //模仿websocket超时处理
      this.timer = setTimeout(() => {
        // debugger
        // console.log(socket, socket.url, socket.readyState, "TTTTTTTTTTTTTT");
        if (socket && socket.readyState === 0) {
          //readyState 0 表示正在建立连接
          console.log("处理webSocket超时!");
          socket.close(3000, "处理webSocket超时!");
        }
      }, 5000);
      // console.log(socket.url, "socket.readyState", socket.readyState,);
      //socket连接通了则继续下一个
      socket.onopen = (e) => {
        // debugger
        console.log(e.currentTarget.url, "socket连接成功");
        // debugger
        clearTimeout(this.timer);
        if (e.currentTarget.url == socket.url) {
          data[itemEquipmentIndex].status = 0;
          this.$set(wsList[curWsIndex], 'enable', true)
          socket && socket.close(3000, "客户端主动关闭");
          this.onOpenSet = setTimeout(() => {
            // debugger
            // wsList[curWsIndex].enable = true
            this.handleCommModule(data, itemEquipmentIndex, curWsIndex);
          }, 1100)
        }
      };
      //socket连接失败，断开连接，将此WS地址状态设为 -1，代表不可用
      socket.onerror = (e) => {
        // debugger
        console.log(e, socket, "socket出错了");
        clearTimeout(this.timer)
        if (this.onOpenSet) {
          clearTimeout(this.onOpenSet)
        }
        if (e.currentTarget.url == socket.url) {
          curWsIndex++;
          setTimeout(() => {
            console.log('222222222222222222222222')
            if (curWsIndex <= wsList.length - 1) {
              socket = null;
              this.handleEquipWS(data, itemEquipmentIndex, curWsIndex);
            } else {
              if (data[itemEquipmentIndex].status === 0) {
                this.handleCommModule(data, itemEquipmentIndex, curWsIndex);
              } else {
                data[itemEquipmentIndex].status = -1;
                this.handleCommModule(data, itemEquipmentIndex, curWsIndex);
              }
              // console.log(data, `轮询第${itemEquipmentIndex}次`)
            }
          }, 1000);
        }
      };

      socket.onclose = (e) => {
        // debugger
        console.log(e.target.readyState, '连接关闭了,状态码为', e.code, '地址：', e.currentTarget.url)
      }
    },
    //设备index + 1
    handleCommModule(data, itemEquipmentIndex, curWsIndex) {
      itemEquipmentIndex++;
      if (itemEquipmentIndex <= data.length - 1) {
        curWsIndex = 0;
        this.handleEquipWS(data, itemEquipmentIndex, curWsIndex);
      } else {
        //处理最终可用的设备列表 根据status == -1 为不可用，进行过滤
        console.log(data, "过滤前的设备");
        const promise = new Promise((resolve) => {
          // setTimeout(() => {
          let list = data.filter((item) => {
            return item.status === 0
          })
          console.log(list, "list home");
          resolve(list);
          // }, 5000);
        });
        promise.then((res) => {
          console.log(res, "过滤完可以连接的设备");

          if (res.length === 0) {
            this.$toast("搜索设备失败，请检查手机与设备是否在同一局域网内");
            //最后剩余可用连接只有1个时，直接连接
          } else if (res.length === 1) {
            this.handleHasOne(res);
            //最后剩余多个可用连接时，弹出弹框，让用户选择
          } else {
            // 查询有效设备中是否有上次连接过的设备
            if (localStorage.getItem("lastUrl")) {
              // 有则将此设备提至第一位
              let lastConnect =
                JSON.parse(localStorage.getItem("lastUrl")) || "";
              res.forEach((item, index) => {
                if (item.hostIp == lastConnect.hostIp) {
                  res.unshift(...res.splice(index, 1));
                  sessionStorage.setItem("curIndex", 0);
                }
              });
              this.onRefresh();
            } else {
              //没有则将上次连接设备信息进行删除
              localStorage.removeItem("lastUrl");
            }
            //调起弹窗
            const dialog = this.$refs.selectPopupRef;
            dialog.apilist = this.filterUrl(res) || [];
            console.log(dialog.apilist, "dialog.apilist home");
            dialog.curIndex =
              JSON.parse(sessionStorage.getItem("curIndex")) ?? null;
            this.$nextTick(() => {
              dialog.show();
              Toast.clear();
            });
          }
        });
      }
    },

    handleHasOne(data) {
      //获取弹窗节点
      const dialog = this.$refs.selectPopupRef;
      //处理WS地址，返回一个只含有WS地址的数组
      let urls = data[0].extra.map((i) => {
        return i.link;
      });
      //存入信息
      sessionStorage.setItem("apiUrls", JSON.stringify(urls));
      sessionStorage.setItem("curIndex", JSON.stringify(0));
      sessionStorage.setItem("curApiItem", JSON.stringify(data[0]));
      this.onRefresh();
      //弹窗渲染 + 显示
      // dialog.btnShow = false;
      dialog.height = "30%";
      dialog.title = "";
      dialog.closeable = false;
      dialog.btnShow = true
      dialog.apilist = this.filterUrl(data) || [];
      dialog.curIndex = JSON.parse(sessionStorage.getItem("curIndex")) ?? null;
      this.$nextTick(() => {
        dialog.show();
        Toast.clear();
      });
    },

    noUrlDownLoad() {
      Toast.loading({
        message: "扫描中...",
        forbidClick: true,
        duration: 0,
      });
      post("/push/web/equipment/list", {})
        .then((res) => {
          if (res.code === 200) {
            if (Array.isArray(res.data) && res.data.length > 0) {
              let data = res.data;
              // let data = [{
              //   deviceId: '2f86bb2ff41cc0141701febc3a3d0eec',
              //   deviceName: '智能设备',
              //   extra: [{
              //     link: 'ws://192.168.3.238:10002'
              //   }],
              //   hostIp: '192.168.3.238',
              //   mac: '80:90:24:aa:cb:40'
              // }]
              let itemEquipmentIndex = 0;
              let curWsIndex = 0;
              this.handleEquipWS(data, itemEquipmentIndex, curWsIndex);
            } else {
              this.$toast("搜索设备失败，请检查手机与设备是否在同一局域网内");
            }
          }
        })
        .catch((err) => {
          console.log(err, "err");
          Toast.clear();
        });
    },

    selectSubmit(data) {
      console.log(data, "data");
      return new Promise((resolve) => {
        setTimeout(() => {
          sessionStorage.setItem("apiUrls", JSON.stringify(data.urls));
          sessionStorage.setItem("curIndex", JSON.stringify(data.curIndex));
          sessionStorage.setItem("curApiItem", JSON.stringify(data.curApiItem));
          this.onRefresh();
          this.socket && this.socket.close(3000, "客户端主动关闭");
          this.socket = null;
          this.handleWS();
          resolve(true);
          this.$set(this.curAppItem, 'state', 0)
        }, 1000);
      });
    },
    //弹窗前重新遍历一遍数组，根据之前遍历时存放的enable属性来判断此设备下多个link中的无效link，并将其过滤
    filterUrl(list) {

      list.forEach((item, idx) => {
        item.extra = item.extra.filter((urls, index) => {
          return urls.enable == true;
        });
        if (!item.extra.length) {
          list.splice(idx, 1);
        }
      });

      return list;
    },

    onRefresh() {
      this.apiUrls = JSON.parse(sessionStorage.getItem("apiUrls")) || [];
      this.curAppItem = JSON.parse(sessionStorage.getItem("curAppItem")) || {};
      this.curUrlIndex = 0;
    },
    // 打开 下载 更新 相关处理
    removeEquipment(wsUrl) {
      const curApiItem = JSON.parse(sessionStorage.getItem("curApiItem"));
      post("/push/equipment/deletelink", {
        link: wsUrl,
        deviceId: curApiItem && curApiItem.deviceId,
      });
    },

    handleWS() {
      let param = {
        cmd: 3,
        data: {
          system: false,
        },
      };
      this.$emit("connectSocket", this.curUrlIndex, param);
    },

    updateClick() {
      let param = {
        cmd: 2,
        data: {
          ...this.curAppItem,
        },
      };
      this.$emit("sendSocket", param);
    },

    onClickLeft() {
      this.$router.go(-1);
    },

    onOpen() {
      this.$refs.selectPopupRef.loading = false;
      this.$refs.selectPopupRef.popupClose();
    },

    onMessage(decryptData) {
      let str = sessionStorage.getItem("appList") || [];
      if (!Array.isArray(str)) {
        str = JSON.parse(str);
      }
      let pkgs = [];
      str.forEach((item) => {
        pkgs.push(item.pkg);
      });
      if (decryptData.cmd == "101") {
        let ret = decryptData.data;
        if (this.curAppItem && ret) {
          if (this.curAppItem.pkg == ret.pkg) {
            if (ret.action == 1) {
              let flag = Number(this.curAppItem.verCode) - Number(ret.verCode) > 0
              this.$set(this.curAppItem, 'state', flag ? 3 : 2)
            } else if (ret.action == 2) {
              if (pkgs.includes(ret.pkg)) {
                let ind = pkgs.indexOf(ret.pkg)
                str.splice(ind, 1)
                sessionStorage.setItem('appList', JSON.stringify(str))
              }
              this.curAppItem.state = 0; //下载

            }
          }
        }
      } else if (decryptData.cmd == "102") {
        this.curAppItem.state = decryptData.data.state;
        if (decryptData.data.state == 4 && !pkgs.includes(decryptData.data.pkg)) {
          str.push(decryptData.data)
          sessionStorage.setItem('appList', JSON.stringify(str))
        }
      } else if (decryptData.cmd == "103") {
        let ret = decryptData.data.appList || null;
        if (this.curAppItem && ret) {
          for (let i = 0; i < ret.length; i++) {
            if (this.curAppItem.pkg.includes(ret[i].pkg)) {
              let flag = Number(this.curAppItem.verCode) - Number(ret[i].verCode) > 0
              this.$set(this.curAppItem, 'state', flag ? 3 : 2)
              return
            } else {
              this.curAppItem.state = 0; //下载
              console.log("下载");
              break;
            }
          }
        }
      }
    },

    close() {
      this.curAppItem.state = 0;
      if (this.$refs.selectPopupRef.popupShow == true) {
        this.$refs.selectPopupRef.popupClose();
      }
      this.apiUrls = []
    },

    onError() {
      this.apiUrls = []
    },

    connected() {
      this.$refs.selectPopupRef.popupClose();
    },
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  },
  mounted() {
    console.log(this.isMobileDevice(), 889)
    const back = this.$refs.imgBack
    if (this.isMobileDevice()) {
      back.style.width = '16.8vw'
    } else {
      back.style.width = '80px'
    }
  },
  created() {
    this.onRefresh();
    this.socket = null;
  },
  computed: {
    swipeList() {
      console.log(this.curAppItem)
      return this.curAppItem.advList;
    },
  },
  beforeDestroy() {
    clearTimeout();
    // this.socket && this.socket.close(3000, "客户端主动关闭");
    this.socket = null;
    this.curUrlIndex = 0;
  },
};
</script>
<style lang="less" scoped>
.loadingButtons {
  position: fixed;
  top: 85%;
  width: 70%;
  left: 50%;
  height: 13.33vw;
  margin-left: -35%;
  border-radius: 25px;
  border-color: transparent;
  border: 1px solid #4cbbf9;
  color: #4cbbf9;
  background: #def3ff;
}

.detail-wrapper {
  position: relative;
  background-color: #f6f8fa;
  height: 100%;

  .my-swipe .van-swipe-item {
    color: #fff;
    text-align: center;
    background-color: #39a9ed;
  }

  .appDetail {
    width: 100%;
    display: flex;
    padding: 5px 5px;
    background: #ffffff;
    overflow: hidden;

    .icon-com {
      width: 16vw;
      height: 16vw;
      border-radius: 15px;
      overflow: hidden;
    }

    .message {
      margin-left: 10px;
      flex: 1;
      overflow: hidden;

      .ellipsis {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .name {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2a2a2a;
        margin-bottom: 5px;
      }

      .desc {
        font-size: 11px;
        color: #c9cfd4;
      }
    }
  }

  .function {
    padding: 0 15px;

    p.title {
      padding: 15px 0 7px 0px;
      color: #2a2a2a;
    }

    p.desc {
      color: #898c8f;
      font-size: 12px;
    }
  }

  .imgcom {
    width: 100%;
    overflow: hidden;
    font-size: 0;
    background: #fff;

    .my-swipe .van-swipe-item {
      .swipeImg {
        width: 100%;
      }
    }
  }

  .backImg {
    position: fixed;
    // width: 16.8vw;
    bottom: 18%;
    right: 5vw;
  }

  .van-button {
    position: fixed;
    top: 85%;
    width: 70%;
    left: 50%;
    height: 13.33vw;
    margin-left: -35%;
    border-radius: 25px;
    border-color: transparent;
    color: #fff;
    background: linear-gradient(135deg, #44d7e8 0%, #157ee4 100%);
  }
}
</style>
