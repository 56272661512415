<template>
    <div class="upload_pc">
        <div class="upload_top" :style="{ height: fileList.length == 0 ? '550px' : '290px' }">
            <div class="header">
                <div class="header_title">
                    <img src="@/assets/icon.png" class="header_icon">
                    <span class="title">远程推送</span>
                </div>
                <span class="tip">温馨提示：上传过程中请不要退出电视应用，手机或电脑应处于同一局域网内</span>
            </div>
            <div class="upload" :style="{ height: fileList.length == 0 ? '460px' : '200px' }">
                <van-uploader v-model="fileList" accept="" multiple :preview-image="false"
                    :before-read="file => beforeRead(file, false)"
                    :after-read="(file, detail) => afterRead(file, detail, false)">
                    <div class="upload_con">
                        <div class="upload_img">
                            <img src="@/assets/pc_upload.png" />
                            <p>点击区域选择文件上传</p>
                        </div>
                        <p class="fileType" :style="{ margin: fileList.length == 0 ? '60px 0 10px' : '20px 0 10px' }">
                            支持传输文件格式：
                        </p>
                        <div style="display: flex;" v-if="fileList.length == 0">
                            <div v-for="(val, ind) in fileType" :key="ind" class="type_item">
                                <img src="@/assets/apk_upload.png" class="type_img" v-if="ind == 0" />
                                <img src="@/assets/doc_upload.png" class="type_img" v-if="ind == 3" />
                                <img src="@/assets/img_upload.png" class="type_img" v-if="ind == 1" />
                                <img src="@/assets/mp_upload.png" class="type_img" v-if="ind == 2" />
                                <img src="@/assets/xlsx_upload.png" class="type_img" v-if="ind == 4" />
                                <img src="@/assets/ppt_upload.png" class="type_img" v-if="ind == 5" />
                                <img src="@/assets/pdf_upload.png" class="type_img" v-if="ind == 6" />
                                <p>{{ val }}</p>
                            </div>
                        </div>
                        <p v-if="fileList.length != 0">.apk、.png、.jpg、.mp4、.doc、.docx、.xlsx、.ppt、.pdf</p>
                        <p class="more" v-if="fileList.length == 0">更多格式正在增加中…</p>
                    </div>
                </van-uploader>

            </div>
        </div>
        <div style=" margin-top: 87px;">
            <div class="warn_tip" v-if="chrome">
                <div style="width:calc(100% - 40px);overflow-x: auto;">
                    <div style="min-width:1100px">
                        <img src="@/assets/warn_tip.png" />
                        <span>谷歌浏览器如碰到传输失败的可尝试请前往</span>
                        <span
                            style="color:#2F6FFD;text-decoration: underline">chrome://flags/#block-insecure-private-network-requests</span>
                        <span>，将插件 Block insecure private network requests 设置为 Disabled</span>
                    </div>
                </div>
                <div>
                    <van-icon name="cross" @click="chrome = false" />
                </div>
            </div>
            <div class="fileList" v-if="fileList.length > 0">
                <div v-for="(item, index) in fileList" :key="index" class="file_item">
                    <img src="@/assets/fail.png" class="type_img" v-if="item.status == 'failed'" />
                    <div v-if="item.status != 'failed'">
                        <img src="@/assets/apk_upload.png" class="type_img" v-if="item.file.name.includes('.apk')" />
                        <img src="@/assets/doc_upload.png" class="type_img" v-if="item.file.name.includes('.doc')" />
                        <img src="@/assets/img_upload.png" class="type_img" v-if="item.file.type.includes('image')" />
                        <img src="@/assets/mp_upload.png" class="type_img" v-if="item.file.name.includes('.mp4')" />
                        <img src="@/assets/pdf_upload.png" class="type_img" v-if="item.file.name.includes('.pdf')" />
                        <img src="@/assets/xlsx_upload.png" class="type_img" v-if="item.file.name.includes('.xlsx')" />
                        <img src="@/assets/ppt_upload.png" class="type_img"
                            v-if="item.file.name.includes('.ppt') || item.file.name.includes('.pptx')" />
                    </div>
                    <div class="upload_right">
                        <div class="name">
                            <p :style="{ color: item.status == 'failed' ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0.8)' }"
                                class="name_top">{{
                                    item.file.name }}</p>
                            <span style="font-size:12px;color: rgba(0,0,0,0.4);">{{ conversion(item.file.size) }}</span>
                        </div>
                        <p v-if="item.status == 'failed'">
                            <img src="@/assets/error_tip.png" />
                            <span class="words" style="color: rgba(0,0,0,0.8);">{{ item.type == 0 ? '上传失败，文件格式错误'
                                : item.type == 1 ? '上传失败，文件已存在' :
                                    '上传失败，稍后再试'
                                }}</span>
                        </p>
                        <p v-if="item.status != 'failed' && (item.uploadPercent == 100 || item.uploadPercent == 0)"
                            class="no_fail">
                            <img src="@/assets/suc_tip.png" v-if="item.uploadPercent == 100" />
                            <img src="@/assets/wait.png" v-if="item.uploadPercent == 0" style="margin-top:0.2vw;" />
                            <span class="words">{{ item.uploadPercent == 0 ? '待上传' : '上传成功' }}</span>
                        </p>
                        <van-progress :percentage="item.uploadPercent" color="#177DDC" track-color="#E1E8F9"
                            :show-pivot="false"
                            v-if="item.status != 'failed' && item.uploadPercent != 100 && item.uploadPercent > 0" />
                    </div>
                </div>
            </div>
            <div style="width:calc(100vw - 30px);overflow: hidden;">
                <div class="appList">
                    <div class="type_top" v-if="menuList.length > 0">
                        <div class="type">
                            <div class="type_name" @click="getList(0)">
                                <p
                                    :style="{ color: type == 0 ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)', fontSize: type == 0 ? '16px' : '12px' }">
                                    {{
                                        menuList[0].name }}</p>
                                <div class="line" :style="{ background: type == 0 ? '#4089F8' : '#FAFAFA' }" />
                            </div>
                            <div class="type_name" @click="getList(1)" v-if="menuList.length > 1">
                                <p
                                    :style="{ color: type == 1 ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)', fontSize: type == 1 ? '16px' : '12px' }">
                                    {{
                                        menuList[1].name }}</p>
                                <div class="line" :style="{ background: type == 1 ? '#4089F8' : '#FAFAFA' }" />
                            </div>
                        </div>
                        <div class="more" @click="goMore" v-if="list.length > 5">
                            <span>查看更多</span>
                            <img src="@/assets/arrow.png" width="16px" />
                        </div>
                    </div>
                    <div style="height: 34px;overflow: hidden;"
                        v-if="typeList.length > 0 && menuList[type].classify == 4">
                        <div class="type_hot">
                            <div class="title_item" v-for="(item, ind) in typeList" :key="ind"
                                :style="{ color: typeHot == ind ? '#2F6FFD' : 'rgba(0,0,0,0.6)' }">
                                <p class="line_left">|</p>
                                <div @click="typeChoose(item, ind)" style="cursor: pointer;">{{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="list_item" v-if="list.length > 0 && !loading">
                        <div class="item" v-for="(item, ind) in list" :key="ind" v-if="ind < 5">
                            <div class="item_con">
                                <div class="item_left">
                                    <img :src="item.icon" />
                                    <div class="appName">{{ item.name }}</div>
                                </div>
                                <div class="item_right" @click="updateClick(item, true)">
                                    <img src="@/assets/pc_down.png" v-if="item.state == 0" class="btn" />
                                    <img src="@/assets/pc_install.png" v-if="item.state == 4" class="btn" />
                                    <img src="@/assets/pc_on.png" v-if="item.state == 2" class="btn" />
                                    <img src="@/assets/pc_update.png" v-if="item.state == 3" class="btn" />
                                    <img src="@/assets/pc_load.png" v-if="item.state == 1" class="btn" />
                                </div>
                            </div>
                            <div class="item_prog" v-if="item.state == 1">
                                <van-progress :percentage="item.describe" color="#177DDC" track-color="#E1E8F9"
                                    :show-pivot="false" style="margin-top: 3px;" />
                                <span>{{ `${item.describe}%` }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="loading" style="text-align: center;margin-top: 20px;">
                        <van-loading size="24px">加载中...</van-loading>
                    </div>
                </div>
            </div>
        </div>
        <img ref="imgBack" @click="onClickLeft" v-if="$route.query.flag" class="backImg" src="@/assets/goback.png" />
    </div>
</template>
<script>
import pushJs from '@/mixins/push.js'
import axios from "axios";
import common from '@/mixins/common.js'
export default {
    mixins: [pushJs, common],
    data() {
        return {
            loading: false,
            uploadUrl: '',
            fileList: [],
            num: [],
            active: 0,
            typeHot: 0, // 热门类别分类
            type: 0, // 装机必备&热门类别,
            list: [],// 应用数据列表
            moveFlag: 0,// 移动按钮显示 0 显示应用列表  1 显示移动按钮(带字的) 2显示移动按钮(不带字)
            uploadPercent: '',//上传文件百分比
            appList: [],// 应用列表
            menuList: [{
                name: '装机必备',
            }, {
                name: '热门类别',
            }], // 菜单栏列表
            typeList: [], // 热门分类列表数据
            fileType: ['.apk', './png/.jpg', '.mp4', '.doc/.docx', '.xlsx', '.ppt', '.pdf'],
            chrome: false, // 是否是谷歌浏览器
        }
    },
    created() {
        const userAgent = navigator.userAgent;
        if (userAgent.indexOf("Chrome") !== -1 && userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Edg") === -1) {
            this.chrome = true
        }
        // this.uploadUrl = 'http://192.168.0.22:23577'
        this.uploadUrl = this.$route.query?.url
        this.getMenuList(true)
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        },
    }

}
</script>
<style lang="less" scoped>
.upload_pc {
    background-color: #FAFAFA;
    min-height: 100vh;
    position: relative;
    font-size: 14px;


    .upload_top {
        height: 600px;
        width: 100%;
        background-color: #ccc;
        min-width: 500px;
        background: url('../../assets/bg_upload.png') no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .header {
            text-align: center;
            color: #fff;
            padding: 70px 0 54px;

            .header_title {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                margin-bottom: 16px;

                .header_icon {
                    width: 20px;
                    margin-right: 10px;
                }
            }

            .tip {
                font-size: 13px;
            }
        }

        .upload {
            position: absolute;
            top: 170px;
            left: 30px;
            width: calc(100% - 60px);
            background-color: #fff;
            border-radius: 10px;
            border: 1px dashed #4287F7;
            font-size: 12px;
            color: #4285F6;

            .van-uploader {
                height: 100%;
                width: 100% !important;
            }

            /deep/.van-uploader__wrapper {
                height: 100% !important;
                width: 100% !important;
            }

            /deep/ .van-uploader__input-wrapper {
                height: 100% !important;
                width: 100% !important;
            }

            /deep/ .van-uploader__upload-text {
                color: #4285F6;
                font-weight: 400;

            }

            .upload_con {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-size: 14px;

                .upload_img {
                    text-align: center;
                    font-size: 14px;
                    color: #4287F7;

                    img {
                        width: 72px;
                    }
                }
            }


            .fileType {
                color: #4285F6;
            }

            .more {
                color: rgba(0, 0, 0, 0.3);
                margin-top: 20px;
            }

            .type_item {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin: 0 10px;

                .type_img {
                    width: 40px;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .warn_tip {
        display: flex;
        align-self: center;
        justify-content: space-between;
        width: calc(100% - 60px);
        // min-width: 1140px;
        background-color: #FFE7E7;
        height: 46px;
        line-height: 46px;
        margin-left: 30px;
        padding: 0 20px;
        color: rgba(0, 0, 0, 0.7981);
        overflow-x: auto;

        img {
            width: 10px;
            height: 11px;
            margin: 15px 5px 0 0;
        }
    }

    .fileList {
        width: calc(100% - 30px);
        margin-left: 30px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .file_item {
            width: calc(33.3% - 15.3px);
            background-color: #fff;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1039);
            border-radius: 10px 10px 10px 10px;
            display: flex;
            justify-content: space-between;
            color: rgba(0, 0, 0, 0.8);
            align-items: center;
            margin: 10px 10px 0 0;
            padding: 10px;
            min-width: 300px;

            .type_img {
                width: 33px;
            }

            .upload_right {
                width: calc(100% - 40px);
                margin-bottom: 5px;

                .name {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .name_top {
                        max-width: calc(100% - 70px);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                }

                img {
                    width: 12px;
                    margin-right: 5px;
                }

                .words {
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.5);
                }

                .no_fail {
                    display: flex;
                    align-items: center;
                }
            }
        }

        /deep/ .van-progress {
            margin-top: 5px !important;
            height: 7px;
            background-color: #F0F0F0 !important;
        }

        /deep/ .van-progress__portion {
            background: #4189F9 !important;
        }
    }

    .appList {
        margin: 12px 0 0;
        min-width: 1200px;

        .type {
            display: flex;
            align-items: center;
            margin: 0 30px 15px;

            .type_name {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                margin-right: 20px;
                cursor: pointer;

                .line {
                    width: 16px;
                    height: 4px;
                    background: #4089F8;
                    border-radius: 2px 2px 2px 2px;
                    margin-top: 5px;
                }
            }
        }

        .type_hot {
            display: flex;
            align-items: center;
            padding: 0 30px;
            width: calc(100vw + 30px);
            overflow: auto;
            padding-bottom: 30px;

            .title_item {
                display: flex;
                align-items: center;
                margin-right: 10px;
                white-space: nowrap;


                .line_left {
                    color: rgba(0, 0, 0, 0.4);
                    margin: -2px 10px 0 0;
                    line-height: 0px;
                }
            }
        }

        .list_item {
            display: flex;
            align-items: center;
            margin: 0 0 0 30px;
            overflow-x: auto;
            width: 100%;

            .item {
                width: 25%;
                height: 95px;
                background: #FFFFFF;
                box-shadow: 0px 2px 8px 0px rgba(5, 5, 5, 0.1045);
                border-radius: 4px 4px 4px 4px;
                margin-right: 20px;
                padding: 18px 5px 0 20px;
                min-width: 260px;
                max-width: 410px;

                .item_con {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    img {
                        width: 60px;
                        margin-right: 10px;
                    }

                    .item_left {
                        width: calc(100% - 90px);
                        display: flex;
                        align-items: center;
                        color: rgba(0, 0, 0, 0.8);
                    }

                    .appName {
                        width: calc(100% - 80px);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .btn {
                        width: 79px;
                        height: 32px;
                        cursor: pointer;
                    }
                }

                .item_prog {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    font-size: 12px;

                    /deep/ .van-progress {
                        width: calc(100% - 42px);
                        margin-right: 9px;
                    }
                }
            }
        }
    }

    .type_top {
        width: calc(100vw - 30px);
        display: flex;
        justify-content: space-between;

        .more {
            display: flex;
            align-items: center;
        }
    }

    .backImg {
        position: fixed;
        bottom: 50px;
        right: 30px;
        width: 60px;
    }
}
</style>